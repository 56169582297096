<template>
  <div class="wrap" v-loading="loading">
    <!-- 上传&列映射 -->
    <el-tabs v-if="!checkUpdateTable.data.length" type="border-card">
      <el-tab-pane label="基本信息">
        <ColMap
          ref="D_Sample"
          table="D_Sample"
        />
      </el-tab-pane>
      <el-tab-pane label="定年分析">
        <ColMap
          ref="D_ICPMS"
          table="D_ICPMS"
        />
      </el-tab-pane>
      <el-tab-pane label="同位素分析">
        <ColMap
          ref="D_otopes"
          table="D_otopes"
        />
      </el-tab-pane>
      <el-tab-pane label="主量元素分析">
        <ColMap
          ref="D_Principal_Element"
          table="D_Principal_Element"
        />
      </el-tab-pane>
      <el-tab-pane label="微量元素分析">
        <ColMap
          ref="D_Micronutrient"
          table="D_Micronutrient"
        />
      </el-tab-pane>
  
    </el-tabs>
    <!-- 质检更新 -->
    <div v-else class="check-update">
      <div class="check-update-message">
        {{ checkUpdateTable.message ?? '' }}
      </div>
      <el-table
        :data="checkUpdateTable.data"
        border
        stripe
        style="width: 100%">

        <el-table-column
          prop="字典名称"
          label="数据列名1"
          width="180"
          sortable
          :filters="checkUpdateTable.dataColNameFilters"
          :filter-method="dataColNameFilterHandler">
          <template slot="header" slot-scope="{ row }">
            <el-tooltip content="点击向下箭头可以进行筛选显示" placement="left" effect="dark">
              <span>数据列名</span>
            </el-tooltip>
          </template>
        </el-table-column>


        <el-table-column
          prop="原始名称"
          label="上传内容"
          width="180">
        </el-table-column>
        <el-table-column
          prop="识别名称"
          label="识别内容">
        </el-table-column>
        <el-table-column
          label="修改内容"
          width="150">
          <template slot-scope="{ row }">
            <DictSelect
              :code="row['字典编号']"
              v-model="row.value" />
          </template>
        </el-table-column>
        <el-table-column
          prop="次数"
          label="次数">
        </el-table-column>
        <el-table-column
          prop="相识度"
          label="相识度">
          <template slot-scope="{ row }">
            {{ row['相识度'] }}%
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="120">
          <template slot-scope="{ row }">
            <el-button type="primary"  @click="onCheckUpdate(row)">质检修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="foot">
      <el-button
        v-if="!checkUpdateTable.data.length"
        type="primary"
        @click="submit">
        提交
      </el-button>
      <el-button
        v-else
        type="primary"
        @click="importGoOn">
        继续录入
      </el-button>
    </div>
  </div>
</template>

<script>
import * as commonApi from '@/api/common'
import * as dSampleApi from '@/api/dSample'
import DictSelect from '@/components/DictSelect.vue';
import ColMap from './components/ColMap.vue'

export default {
  data() {
    return {
      loading: false,
      data: {
        D_Sample: [], // 基本信息
        D_ICPMS: [], // 定年分析
        D_otopes: [], // 同位素分析
        D_Micronutrient: [], // 主量元素分析
        D_Principal_Element: [] // 微量元素分析
      },
      checkUpdateTable: {
        message: '', // 提交结果
        data: [], // 质检修改talble数据
        dataColNameFilters: [] // 数据列名筛选
      }
    }
  },
  components: {
    DictSelect,
    ColMap
  },
  methods: {
    async LoadTableInfo() {
      try {
        this.loading = true
        // 基本信息
        let { data: D_Sample } = await commonApi.LoadTableInfo(86)
        this.data.D_Sample = D_Sample.tableColumns.map(col => {
          return {
            columnId: col.columnId, // 列id
            columnName: col.columnName, // 列名
            columnCNName: col.columnCNName, // 列中文名
            xlsColumnName: '' // excel列名
          }
        })

        // 定年分析
        let { data: D_ICPMS } = await commonApi.LoadTableInfo(106)
        this.data.D_ICPMS = D_ICPMS.tableColumns.map(col => {
          return {
            columnId: col.columnId,
            columnName: col.columnName,
            columnCNName: col.columnCNName,
            xlsColumnName: ''
          }
        })

        // 同位素分析
        let { data: D_otopes } = await commonApi.LoadTableInfo(107)
        this.data.D_otopes = D_otopes.tableColumns.map(col => {
          return {
            columnId: col.columnId,
            columnName: col.columnName,
            columnCNName: col.columnCNName,
            xlsColumnName: ''
          }
        })

         // 微量元素分析
        let { data: D_Micronutrient } = await commonApi.LoadTableInfo(104)
        this.data.D_Micronutrient = D_Micronutrient.tableColumns.map(col => {
          return {
            columnId: col.columnId,
            columnName: col.columnName,
            columnCNName: col.columnCNName,
            xlsColumnName: ''
          }
        })

       // 主量元素分析
        let { data: D_Principal_Element } = await commonApi.LoadTableInfo(91)
        this.data.D_Principal_Element = D_Principal_Element.tableColumns.map(col => {
          return {
            columnId: col.columnId,
            columnName: col.columnName,
            columnCNName: col.columnCNName,
            xlsColumnName: ''
          }
        })
      } catch (error) {
        this.loading = false
      }
    },
    submit() {

      this.data.D_Sample = this.$refs.D_Sample.getFormData()
      this.data.D_ICPMS = this.$refs.D_ICPMS.getFormData()
      this.data.D_otopes = this.$refs.D_otopes.getFormData()
      this.data.D_Micronutrient = this.$refs.D_Micronutrient.getFormData()
      this.data.D_Principal_Element = this.$refs.D_Principal_Element.getFormData()

      if (!this.data.D_Sample.length) {
        this.$message.warning({
          message: '请上传基本信息'
        })
        return
      }

      let params = {
        _D_Sample: this.data.D_Sample, // 基本信息
        _D_ICPMS: this.data.D_ICPMS, // 定年分析
        _D_otopes: this.data.D_otopes, // 同位素分析
        _D_Principal_Element: this.data.D_Principal_Element, // 主量元素分析
        _D_Micronutrient: this.data.D_Micronutrient // 微量元素分析
      
      }
      this.loading = true
      dSampleApi.addByZdy(params).then(res => {
        this.loading = false
        if (res.status) {
          // 提交成功记录上次提交数据，供下次使用上次映射列使用
          localStorage.setItem('nonStandardLastSubmitData', JSON.stringify(this.data))

          let data = JSON.parse(res.data)
          data.forEach(item => {
            item.value = ''
            item.val = ''
          })
          console.log('data', data)
          let dataColNames = data.map(item => item['字典名称'])
          dataColNames = Array.from(new Set(dataColNames))
          this.checkUpdateTable.dataColNameFilters = dataColNames.map(val => {
            return {
              text: val,
              value: val
            }
          })
          this.checkUpdateTable.data = data
          var vv =res.message
          this.$message.warning({
          message: '自动质检后，发现可能存在的问题数据，请人工修改确认'
        })

          this.checkUpdateTable.message = vv
        } else {
          this.$message.error('提示：'+res.message)
          this.checkUpdateTable.data = []
          this.checkUpdateTable.message = ''
          // 添加报告下载链接
          const logPath =  res.logpath;
          const logUrl = logPath.replace(/\\/g, '/'); // 将路径中的反斜杠替换为斜杠
          const downloadLink = `<a href="${logUrl}" download>查看质检报告</a>`;
          this.$alert(`${downloadLink}<br>${res.message}`, '用户数据自适应匹配录入-质检报告', {
            dangerouslyUseHTMLString: true,
            customClass: 'custom-alert',
            style: {
              width: 'auto', // 设置宽度自适应
              maxWidth: '90vw' // 设置最大宽度为视口宽度的90%
            }
          })
        }
      }).catch(err => {
        this.loading = false
      })
    },
    onCheckUpdate(row) {
      console.log(row)
      if (!row.value) {
        this.$message.error('请选择修改名称')
        return
      }
      this.loading = true
      dSampleApi.zdyDataCheckUpdate({
        zdname: row['字段名称'],
        importq: row['原始名称'],
        zdvalue: row.value,
        keyid: row.keyid
      }).then(res => {
        this.loading = false
        if (res.status) {
          this.$message.success(res.message)
        }
      }).catch(err => {
        this.loading = false
      })
    },
    dataColNameFilterHandler(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    importGoOn() {
      this.reset()
    },
    reset() {
      this.data.D_Sample = [] // 基本信息
      this.data.D_ICPMS = [] // 定年分析
      this.data.D_otopes = [] // 同位素分析
      this.data.D_Micronutrient = [] // 主量元素分析
      this.data.D_Principal_Element = [] // 微量元素分析

      this.checkUpdateTable.data = []
      this.message = []
    }
  },
  mounted() {
  
  }
}
</script>

<style lang="less" scoped>
.wrap {
  background-color: #fff;
  .check-update {
    padding: 20px;
    min-height: 500px;
    &-message {
      margin-bottom: 12px;
      color: #67C23A;
    }
  }
  .foot {
    padding: 20px;
    border-top: 1px solid #DCDFE6;
  }
}
</style>
